export default {

    userId: "767482070838018058",
    github: "Phaeliss",
    description: "Hello, I'm Phaelis, I've been coding for about 5 years. I started coding with node.js, then I improved myself in areas such as web python, I still use node.js in general, I still have knowledge about libraries such as next.js and electron. Even though I don't know some languages ​​very well, I still try to improve myself and learn all programming languages. I know almost all programming languages. I am a 12th grade student and my goal is to study computer and software engineering.",
    about: {
        description: "Hello. My Name İs <span class='text-primary'>Phaelis</span>. I am <span class='text-primary'>17 years</span> old. I am a <span class='text-primary'>2rd year student</span> and I interested in <span class='text-primary'>Software</span>. I've been coding <span class='text-primary'>since 2019</span> and I want to improve myself in this field.",
        liveIn: "İstanbul, Şişli",
        birthday: "16 June, 2007",
        gender: "Man"
    }

}
